import { useFlag } from '@innovamat/flags';
import { authService, storage } from '@innovamat/radiance-utils';
import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import type {
  AuthError,
  LoginMethods,
  LoginRequest,
  LoginResponse,
} from '@innovamat/social-login';
import { useAuthDispatch, useAuthState } from '@innovamat/social-login';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import userActions from '../../store/auth/actions';
import authDataSource from '../../store/auth/dataSource';
import { checkAllowedRoles } from '../../utils/checkAllowedRoles';
import { setEventData } from '../../utils/setEventData';
import type { NotificationType } from '../LoginWrapper/LoginWrapper';

type UseLogin = {
  handleSetCredentials: (value: string, name: string) => void;
  isLoading: boolean | undefined;
  showPassword: boolean;
  displayError: boolean;
  onLogin: (type: LoginMethods, payload?: LoginRequest | undefined) => void;
  credentials: {
    username: string;
    password: string;
  };
  error: AuthError | undefined;
  notification: NotificationType;
  handleDisplayError: (value: boolean) => void;
  handleToggleShowPassword: () => void;
  isValid: () => boolean;
  showCleverBtn: boolean;
  handleIsUsa: (value: boolean) => void;
};

export default function useLogin(): UseLogin {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authDispatch = useAuthDispatch();
  const [isUSA, setIsUSA] = useState(false);
  const { onLogin, loading, error, loginResponse } = useAuthState();

  const getIsLoading = (): boolean | undefined =>
    loading && Object.values(loading).some(Boolean);
  const isLoading = getIsLoading();

  const [showPassword, setShowPassword] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const { value: showCleverLoginButton } = useFlag(
    'showCleverLoginButton',
    false
  );

  const { apiUsers } = useRegionalConfigVariables();

  const handleLoginSucces = async (tokenData: LoginResponse): Promise<void> => {
    const { roles } = authService.mapTokenToUser(tokenData);
    const isAllowedRole = checkAllowedRoles(roles);

    if (!isAllowedRole) {
      authDispatch({
        type: 'SET_ERROR',
        payload: {
          detail: 'user.is_not_allowed',
          status: 403,
          title: 'user.is_not_allowed',
          type: 'error',
        },
      });
      return;
    }

    setTimeout(() => {
      setEventData('webapp_login');
    }, 3000);

    storage.tokenInfo.set(tokenData);

    dispatch(userActions.loginSuccess(tokenData));

    const { data: dataDevice } = await authDataSource.meDevice(
      tokenData.access_token || '',
      apiUsers
    );

    dispatch(userActions.deviceCodeSuccess(dataDevice));
    storage.device.set(dataDevice.device_code || '');
  };

  useEffect(() => {
    if (loginResponse) {
      handleLoginSucces(loginResponse);
    }
  }, [loginResponse]);

  const mslWithNoAccOnInnovamat = error?.title === 'msal_auth_invalid';
  const userIsNotAllowed = error?.title === 'user.is_not_allowed';
  const isUserDisabled =
    error?.title === 'user.is_disabled' ||
    window.location.search.includes('warning=isUserDisabled');
  const isNetworkError = error?.title === 'user.is_network_error';

  useEffect(() => {
    setDisplayError(!!error);
    if (error) {
      setEventData(
        'login_attempt_failed',
        {
          username: credentials.username,
        },
        false
      );
    }
  }, [error]);

  const handleSetCredentials = (value: string, name: string): void => {
    setCredentials((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };

  const getNofication = (): NotificationType => {
    if (isUserDisabled) {
      return {
        message: t(
          'Login.messageError.user.is_disabled',
          'Tu cuenta ha sido desactivada por tu escuela. Habla con ellos para poder acceder.'
        ),
        type: 'error',
      };
    }

    if (!displayError) return undefined;

    if (userIsNotAllowed) {
      return {
        message: t('Login.messageError.user.is_not_allowed'),
        type: 'error',
      };
    }

    if (isNetworkError) {
      return {
        message: t(
          'Login.messageError.login.isNetworkError',
          'Parece que ha habido un problema, comprueba tu conexión a Internet.'
        ),
        type: 'error',
      };
    }

    if (mslWithNoAccOnInnovamat) {
      return {
        message: t(
          'Login.messageError.user.msal_auth_invalid',
          'Has accedido con tu cuenta Microsoft, pero no eres usuario de Innovamat. Habla con tu escuela para registrarte.'
        ),
        type: 'error',
      };
    }

    return {
      message: (
        <Trans
          i18nKey="Login.messageError.incorrectUser"
          components={{
            b: <b />,
            a: (
              <a
                href={t('common.accountProblems.url')}
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      ),
      type: 'error',
    };
  };

  const notification = getNofication();

  const handleDisplayError = (value: boolean): void => {
    setDisplayError(value);
  };

  const handleToggleShowPassword = (): void => {
    setShowPassword((prev) => !prev);
  };

  const isValid = (): boolean =>
    !(credentials.username.trim() && credentials.password.trim());

  const handleIsUsa = (value: boolean): void => {
    setIsUSA(value);
  };

  const showCleverBtn = isUSA && showCleverLoginButton;

  return {
    handleSetCredentials,
    isLoading,
    showPassword,
    displayError,
    onLogin,
    credentials,
    error,
    notification,
    handleDisplayError,
    handleToggleShowPassword,
    isValid,
    showCleverBtn,
    handleIsUsa,
  };
}
