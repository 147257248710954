import styled from '@emotion/styled';
import {
  ApexChartWrapper,
  Loader,
  Typography,
  useGlimmerTheme,
} from '@innovamat/glimmer-components';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  data?: { name: string; score: number }[];
  columnWidth?: string;
  minWidth?: string | number;
};

const ChartContainer = styled.div<{ minWidth: number | string }>`
  min-width: ${({ minWidth }) =>
    typeof minWidth === 'number' ? `${minWidth}px` : minWidth};
  width: 100%;
  overflow-y: hidden;
  max-height: 350px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const OverflowContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
`;

const COLUMN_MIN_WIDTH = 60;

export function MockExamsDistributionChart({
  data,
  columnWidth = '18px',
  minWidth,
}: Props): JSX.Element {
  const theme = useGlimmerTheme();
  const { t } = useTranslation();

  const chartOptions = {
    chart: {
      type: 'bar' as const,
      fontFamily: 'Roboto, Arial, sans-serif',
      toolbar: {
        show: false,
      },
      selection: {
        yaxis: {
          min: 0,
          max: 100,
        },
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: columnWidth,
        distributed: true,
        borderRadius: 2,
        borderRadiusApplication: 'end' as const,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },

    xaxis: {
      categories: data?.map((item) => item.name),

      axisTicks: {
        show: false,
        height: 100,
      },

      axisBorder: {
        show: true,
        color: 'black',
        height: 1,
      },

      labels: {
        rotateAlways: true,
        hideOverlappingLabels: false,
        trim: false,
      },
    },

    yaxis: {
      decimalsInFloat: 1,
      tickAmount: 5,
      max: 100,
    },
    fill: {
      opacity: 1,
    },
    colors: [
      ({ value }: any) => {
        if (value > 90) {
          return theme.tokens.color.specific.reports.semantic['super-high']
            .value;
        } else if (value > 70) {
          return theme.tokens.color.specific.reports.semantic['high'].value;
        } else if (value > 50) {
          return theme.tokens.color.specific.reports.semantic['medium'].value;
        } else {
          return theme.tokens.color.specific.reports.semantic['low'].value;
        }
      },
    ],
  };
  const chartSeries = [
    {
      name: 'Students',
      data: data?.map((item) => item.score) || [],
    },
  ];

  const containerRef = useRef<HTMLDivElement>(null);
  const loaderWidth = useRef<string | number>('80vw');

  useLayoutEffect(() => {
    loaderWidth.current = containerRef.current?.clientWidth || '80vw';
  }, []);

  if (!data) {
    return <Typography.Body1>No data available</Typography.Body1>;
  }

  const minWidthCalc = minWidth || data.length * COLUMN_MIN_WIDTH;

  return (
    <>
      <Typography.Body1>{t('mock-exams-drawer.chart.title')}</Typography.Body1>
      <OverflowContainer ref={containerRef}>
        <ChartContainer minWidth={minWidthCalc}>
          <ApexChartWrapper
            id="mock-exams-distribution-chart"
            type="bar"
            height={'325px'}
            width={'100%'}
            options={chartOptions}
            series={chartSeries}
            loadingComponent={
              <LoaderContainer style={{ width: loaderWidth.current }}>
                <Loader size="md" />
              </LoaderContainer>
            }
          />
        </ChartContainer>
      </OverflowContainer>
    </>
  );
}
