import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import type { Dimension } from '../../types/Dimension';
import { Dimensions } from '../../types/Dimension';
import type { BigIdeaTag } from '../../types/SmartGuide';
import { ContentConnection } from '../../types/SmartGuide';
import { EMPTY_TEXT_VALUE } from '../../utils/values';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

type Props = {
  tags?: BigIdeaTag[];
};

function BigIdeasTags({ tags }: Props): JSX.Element {
  const { t } = useTranslation();

  const mapBigIdeaToDimension = (tag: BigIdeaTag): Dimension => {
    const {
      DISCOVERING_SHAPE_AND_SPACE,
      EXPLORING_CHANGING_QUANTITIES,
      REASONING_WITH_DATA,
      TAKING_WHOLES_APART,
    } = ContentConnection;

    switch (tag.contentConnection) {
      case REASONING_WITH_DATA:
        return Dimensions.CONNECTIONS;
      case EXPLORING_CHANGING_QUANTITIES:
        return Dimensions.COMMUNICATION;
      case TAKING_WHOLES_APART:
        return Dimensions.REASONING;
      case DISCOVERING_SHAPE_AND_SPACE:
      default:
        return Dimensions.PROBLEM;
    }
  };

  if (!tags?.length) {
    return <></>;
  }

  return (
    <SimpleBlock title={t('digitalguides.big_ideas')}>
      <Container>
        {tags.map((tag: BigIdeaTag, index) => {
          return (
            <Tag
              key={`${tag.text}-${index}`}
              text={t(tag.text, EMPTY_TEXT_VALUE)}
              isEnabled={true}
              dimension={mapBigIdeaToDimension(tag)}
            />
          );
        })}
      </Container>
    </SimpleBlock>
  );
}

export { BigIdeasTags };
