import type { Credentials } from '@innovamat/social-login';
import { AuthProvider } from '@innovamat/social-login';
import { QueryClientProvider } from '@tanstack/react-query';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-domv6';
import AppRouter from './app/AppRouter';
import { queryClient } from './config/queryClient';
import ThemeWrapper from './theme/ThemeWrapper';
// import reportWebVitals from './reportWebVitals';
import {
  RegionalConfigVariablesProvider,
  isUSRegion,
  useRegionalConfigVariables,
} from '@innovamat/regional-config-variables';
import { ConfigCatProvider } from 'configcat-react';
import { useEffect, useState } from 'react';
import './assets/scss/global.scss';
import './config/language/i18n';
import useQueryParams from './hooks/useQueryParams';
import { store } from './store/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const {
  NX_GOOGLE_CLIENT_ID,
  NX_MSAL_CLIENT_ID,
  NX_USERS_CLIENT_ID,
  NX_GOOGLE_SECRET,
  NX_CLEVER_CLIENT_ID,
  NX_CLEVER_DISTRICT_ID,
  NX_CLEVER_SECRET,
  NX_CONFIG_CAT_KEY,
} = process.env;

const initialCredentials: Credentials = {
  apolloUrl: '',
  usersClientId: NX_USERS_CLIENT_ID!,
  apiUsersUrl: '',
  msalClientId: NX_MSAL_CLIENT_ID!,
  googleClientId: NX_GOOGLE_CLIENT_ID!,
  googleSecretId: NX_GOOGLE_SECRET!,
  cleverClientId: NX_CLEVER_CLIENT_ID!,
  cleverDistrictId: NX_CLEVER_DISTRICT_ID!,
  cleverSecret: NX_CLEVER_SECRET!,
};

function AuthProviderWrapper({ children }: { children: React.ReactNode }) {
  const query = useQueryParams();

  const getAutoLogin = () => {
    const autologin = query.get('autologin');
    return {
      google: autologin === 'google',
      msal: autologin === 'msal',
      clever: autologin === 'clever',
    };
  };

  const [credentials, setCredentials] =
    useState<Credentials>(initialCredentials);

  const regionalConfigVariables = useRegionalConfigVariables();

  useEffect(() => {
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      apolloUrl: regionalConfigVariables.apolloServer,
      apiUsersUrl: regionalConfigVariables.apiUsers,
    }));
  }, [regionalConfigVariables]);

  if (credentials.apolloUrl === '' || credentials.apiUsersUrl === '') {
    return <></>;
  }

  return (
    <AuthProvider credentials={credentials} autologin={getAutoLogin()}>
      {children}
    </AuthProvider>
  );
}

const environment = process.env.NX_ENVIRONMENT || 'local';
const fastApiUrl = process.env.NX_CORPORATE_WEBSITE || '';
const region = (await isUSRegion(fastApiUrl)) ? 'us' : 'nonUs';

root.render(
  <RegionalConfigVariablesProvider environment={environment} region={region}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProviderWrapper>
            <ThemeWrapper>
              <ConfigCatProvider sdkKey={NX_CONFIG_CAT_KEY!}>
                <AppRouter />
              </ConfigCatProvider>
            </ThemeWrapper>
          </AuthProviderWrapper>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </RegionalConfigVariablesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
