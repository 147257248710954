import { storage } from '@innovamat/radiance-utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-domv6';
import useAppSelector from '../../hooks/useAppSelector';
import authDataSource from '../../store/auth/dataSource';
import { getUser, isRole } from '../../store/auth/selectors';
import type { AppState } from '../../store/root/root.interfaces';
import {
  actionGetRegionParameters,
  actionSetBridgeIsReady,
  actionSetSelectedStage,
} from '../../store/unityData/unityData.actions';
import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput';
import Icon from '../Icon';

import { useRegionalConfigVariables } from '@innovamat/regional-config-variables';
import { useAuthState } from '@innovamat/social-login';
import SceneSpinner from '../SceneSpinner';
import {
  BackButtonWrapper,
  Footer,
  ForgotPassword,
  ModalContent,
  Selector,
  SelectorArea,
  StyledButton,
  Subtitle,
  Title,
  Wrapper,
} from './StageSelector.styled';

function StageSelector(): JSX.Element {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(getUser);
  const [isModalOpened, setModalOpen] = useState(false);
  const [isPasswordCorrect, setPasswordCorrect] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const { isParent, isStudentJunior } = useAppSelector(isRole);
  const { loggedFrom } = useAuthState();

  const isSocialLogin =
    loggedFrom === 'google' || loggedFrom === 'msal' || loggedFrom === 'clever';

  const {
    unityData: { Account },
  } = useSelector(({ uData }: AppState) => ({
    unityData: uData,
  }));

  const handleSetSelectedStage = async (stage: string): Promise<void> => {
    function waitAfterDispatch(stg: string): unknown {
      return new Promise<void>((resolve) => {
        window.localStorage.setItem('storedSelectedStage', stg);
        dispatch(actionGetRegionParameters(user!, stg));
        dispatch(actionSetSelectedStage(stg));

        resolve();
      });
    }
    await waitAfterDispatch(stage);
    navigate('/home');
  };

  const handleLogoutClick = (): void => {
    setModalOpen(true);
  };

  const { apiUsers } = useRegionalConfigVariables();

  const handleLogoutAction = async (): Promise<void> => {
    try {
      if (!isSocialLogin) {
        await authDataSource.login(
          {
            username: user!.email,
            password: inputValue,
          },
          apiUsers
        );
      }
      window.localStorage.removeItem('storedSelectedStage');
      window.sessionStorage.clear();

      storage.clear();
      window.location.href = '/';
    } catch (error) {
      setPasswordCorrect(false);
    }
  };
  useEffect(() => {
    const selectedStage = window.localStorage.getItem('storedSelectedStage');
    if (selectedStage !== null) {
      handleSetSelectedStage(selectedStage);
    }
  }, []);

  useEffect(() => {
    dispatch(actionSetBridgeIsReady(false));
  }, []);

  useEffect(() => {
    setPasswordCorrect(true);
  }, [inputValue]);

  useEffect(() => {
    if (isParent || isStudentJunior) navigate('/home');
  }, [isParent, isStudentJunior]);

  if (!Account?.id) return <SceneSpinner />;

  return (
    <Wrapper>
      <BackButtonWrapper>
        <StyledButton onClick={() => handleLogoutClick()}>
          <Icon icon="logout-1" size={32} />
          {t('innovacomponents.userPopUp.logout')}
        </StyledButton>
      </BackButtonWrapper>
      <SelectorArea>
        <Selector
          onClick={() => handleSetSelectedStage('earlyYears')}
          role="button"
          tabIndex={0}
          onKeyPress={() => handleSetSelectedStage('earlyYears')}
        >
          {t('general.early_years')}
        </Selector>
        <Selector
          onClick={() => handleSetSelectedStage('primary')}
          role="button"
          tabIndex={0}
          onKeyPress={() => handleSetSelectedStage('primary')}
        >
          {t('general.primary')}
        </Selector>
      </SelectorArea>
      <Footer>{`${Account?.name}`}</Footer>
      <ModalContent
        width="60%"
        size="auto"
        open={isModalOpened}
        onSubmit={() => handleLogoutAction()}
        onClose={() => setModalOpen(false)}
        showCancelBtn
        submitText={t('Logout.disconect', 'Desconnectar')}
        cancelText={t('Logout.cancel', 'Cancel·lar')}
        disableSubmit={isSocialLogin ? false : inputValue === ''}
      >
        <Title>{t('Logout.title', "Segur que vols sortir d'Innovamat?")}</Title>
        <Subtitle>
          {t(
            'Logout.subtitle',
            'No cal que et desconectis per seguir utilitzant la App per a qualsevol dels cursos de la teva escola.'
          )}
        </Subtitle>
        {!isSocialLogin && (
          <>
            <FloatingLabelInput
              label={t('Logout.label', 'Contrasenya del compte {{email}}', {
                email: user!.email,
              })}
              isPasswordCorrect={isPasswordCorrect}
              parentCallback={setInputValue}
            />
            <ForgotPassword>
              <a
                href={`https://app.innovamat.com/${language}/forgot-password`}
                target="_blank"
                rel="noreferrer"
              >
                {t('Logout.forgotPassword', 'No recordes la teva contrasenya?')}
              </a>
            </ForgotPassword>
          </>
        )}
      </ModalContent>
    </Wrapper>
  );
}

export default StageSelector;
