import styled from '@emotion/styled';
import { getTypography, theme } from '@innovamat/glimmer-components';
import { ModalV2 as Modal } from '@innovamat/innova-components';
import { Session } from '@innovamat/resource-viewer';
import { TFunction } from 'i18next';
import 'tippy.js/dist/tippy.css';
import { BigIdeasTags } from '../../../components/big-ideas-tags';
import { MathPractices } from '../../../components/math-practices';
import { NewStandardContent } from '../../../components/new-standard-content';
import { Stage } from '../../../types/Stage';
import AssetBlock from '../../components/AssetBlock';
import ChallengesBlock from '../../components/ChallengesBlock/ChallengesBlock';
import ContentBlock from '../../components/content-block';
import DimensionsBlock from '../../components/DimensionsBlock';
import DisclaimerBlock from '../../components/DisclaimerBlock';
import Heading from '../../components/Heading';
import ImagesModal, {
  ImageModal,
} from '../../components/ImagesModal/ImagesModal';
import { Content, MainContent, Wrapper } from '../../components/Layout';
import { Separator } from '../../components/Separator';
import SimpleBlock from '../../components/SimpleBlock';
import VariationsBlock from '../../components/VariationsBlock';
import { useDigitalGuides } from '../../context';
import { ScrollPosition } from '../../types/prepr';
import EarlyYearGuideProvider from './context/EarlyYearGuideProvider';
import useEarlyYearGuide from './context/useEarlyYearGuide';
import {
  EARLY_YEAR_CONTENT_BLOCKS,
  EARLY_YEAR_CONTENT_BLOCKS_ICONS,
} from './EarlyYear.mappers';
import { ChallengesResponse } from './types/challenges';
import { EarlyYearDigitalGuide } from './types/earlyYearDigitalGuide';

const AssetContent = styled.div`
  flex: 1;
`;

export const ModalContent = styled.div`
  position: relative;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: -1px;
  top: -22px;
  svg rect {
    fill: ${theme.colors.white};
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > div > p {
    ${({ theme }) => getTypography(theme, 'Subtitle 1')};
    color: ${({ theme }) => theme.colors.dark['01']};
  }
`;

type Props = {
  resource: Session;
  guide: EarlyYearDigitalGuide;
  images: ImageModal[];
  canMarkAsDone?: boolean;
  onMarkChallengeAsDone?: (challengeId: string, value: boolean) => void;
  scrollPosition: ScrollPosition;
  challenges?: ChallengesResponse | undefined;
  onEvent: (key: string, data: any) => void;
  t: TFunction<'translation', undefined, 'translation'>;
};

function App() {
  const { guide, resource, images, defaultImage, onClickAsset, t } =
    useEarlyYearGuide();

  const { relatedItemsComponent } = useDigitalGuides();

  const linkedLists =
    'linkedLists' in resource ? resource.linkedLists : undefined;

  if (!guide) return null;

  return (
    <>
      <Wrapper>
        <MainContent>
          <Content>
            <SimpleBlock
              title={t('digitalguides.ey.summary')}
              text={guide.summary}
            />

            <TagsContainer>
              <ContentBlock
                contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
                contentBlocks={guide.contentBlocks}
                items={EARLY_YEAR_CONTENT_BLOCKS}
              />
              <BigIdeasTags tags={guide?.bigIdeas} />
              <NewStandardContent
                stage={Stage.EarlyYear}
                summary={
                  { newStandardContent: guide.newStandardContent } as any
                }
              />
              <MathPractices tags={guide.newMathPractices} />
              <DimensionsBlock
                dimensions={guide.dimensions}
                title={t('digitalguides.processes')}
              />
            </TagsContainer>

            <DisclaimerBlock disclaimer={guide.dimensionsDisclaimer} />
            <ChallengesBlock
              challenges={guide.challenges}
              contentIcons={EARLY_YEAR_CONTENT_BLOCKS_ICONS}
            />
          </Content>
          <AssetContent>
            <AssetBlock onClickAsset={onClickAsset} />
          </AssetContent>
        </MainContent>
        <VariationsBlock variations={guide.variations} />
        {linkedLists && linkedLists.length > 0 && (
          <>
            <Separator />
            <Heading>{t('Sessio.RelatedItems')}</Heading>
            {relatedItemsComponent}
          </>
        )}
      </Wrapper>
      <Modal
        open={!!defaultImage}
        showSubmitBtn={false}
        showCancelBtn={false}
        backgroundColor="transparent"
        overlayBgOpacity={0.8}
        clickOutsideClose
        onClose={() => {
          onClickAsset(null);
        }}
        padding="4px"
      >
        <ImagesModal
          listOfImages={images}
          defaultImage={defaultImage}
          onClickImage={onClickAsset}
        />
      </Modal>
    </>
  );
}

function EarlyYearDigitalGuideWrapper({
  guide,
  resource,
  images,
  canMarkAsDone,
  onMarkChallengeAsDone,
  scrollPosition,
  challenges,
  onEvent,
  t,
}: Props) {
  return (
    <EarlyYearGuideProvider
      challenges={challenges}
      guide={guide}
      images={images}
      canMarkAsDone={canMarkAsDone}
      resource={resource}
      onMarkChallengeAsDone={onMarkChallengeAsDone}
      scrollPosition={scrollPosition}
      onEvent={onEvent}
      t={t}
    >
      <App />
    </EarlyYearGuideProvider>
  );
}

export default EarlyYearDigitalGuideWrapper;
